import Vue from "vue";
import Slideshow from "./Slideshow.vue";

export const slideshow = (configs) =>
  configs.map(
    (c) =>
      new Vue({
        el: c.el,
        name: "SlideshowWrapper",
        data: {
          items: c.items,
        },
        render(h) {
          return h(Slideshow, {
            props: {
              items: c.items,
            },
          });
        },
      })
  );

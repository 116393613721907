<template>
  <button
    v-if="resultsShown"
    class="py-3 link--filter text-red font-bold text-sm"
    @click="toggleMobileFilters"
  >
    {{ text }}
  </button>
</template>
<script>
import { TOGGLE_MOBILE_FILTERS } from "@/search/store/mutations";

import { mapState, mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["totalHits", "showMobileFilters"]),
    ...mapState({
      resultsShown: ({ ui }) => ui.resultsShown,
    }),
    text() {
      return this.showMobileFilters ? `Hide Filters` : `Show Filters`;
    },
  },
  methods: {
    toggleMobileFilters() {
      this.$store.commit(TOGGLE_MOBILE_FILTERS);
    },
  },
};
</script>

import { SET_LOCATIONS_QUERY } from "@/search/store/mutations";

export const FACETS = [];

export const DISJUNCTIVE_FACETS = [
  {
    displayTitle: "Funders",
    name: "funders",
    type: "CheckboxFilter",
    displayOrder: 1,
    values: [],
  },
  {
    displayTitle: "Actions",
    name: "actions",
    type: "CheckboxFilter",
    displayOrder: 2,
    values: [],
  },
  {
    displayTitle: "Focus",
    name: "focus",
    type: "CheckboxFilter",
    displayOrder: 3,
    values: [],
  },
  {
    displayTitle: "Project status",
    name: "status",
    type: "CheckboxFilter",
    displayOrder: 3,
    values: [],
  },
  {
    displayTitle: "Locations",
    name: "locations",
    type: "SearcheableCheckboxFilter",
    searchMutation: SET_LOCATIONS_QUERY,
    displayOrder: 4,
    searchable: true,
    queryStateKey: "locationsQuery",
    values: [],
  },
];

const currentYear = new Date().getFullYear();
const minYear = 1990;

export const NUMERIC_RANGE_REFINEMENTS = [
  {
    displayTitle: "Commenced",
    name: "commencementYear",
    type: "NumericRangeFilter",
    displayOrder: 1,
    values: {
      min: minYear,
      max: currentYear,
    },
    bounds: {
      min: minYear,
      max: currentYear,
    },
  },
];

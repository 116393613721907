<template>
  <div>
    <div class="flex flex-wrap items-center lg:hidden">
      <div class="px-4 md:w-2/5 lg:w-1/4 lg:px-8">
        <h4 class="h-trim">Results</h4>
      </div>

      <div class="w-3/5 ml-auto text-right lg:hidden lg:px-8">
        <result-count />
      </div>
    </div>

    <hr class="hr lg:hidden" />

    <div class="flex flex-wrap items-center">
      <div class="hidden md:w-2/5 lg:w-1/4 lg:px-8 lg:block">
        <h4 class="h-trim">Results</h4>
      </div>

      <div class="w-full lg:w-3/4 lg:px-8">
        <div class="flex flex-wrap items-center">
          <div class="hidden w-1/3 mr-auto lg:block">
            <result-count />
          </div>
          <div v-if="isMobile" class="w-1/3 lg:mx-auto">
            <mobile-filter-button />
          </div>
          <div class="w-2/3 ml-auto text-right">
            <per-page />
          </div>
        </div>
      </div>
    </div>

    <hr class="hr" />

    <!-- The Results -->
    <div class="flex flex-wrap">
      <div
        class="hidden md:block md:w-1/3 md:pl-4 md:pr-6 lg:w-1/4 lg:px-8"
      >
        <div class="flex items-center mb-6 md:pt-4">
          <h4 class="h-trim">Filters</h4>
          <reset-all-filters class="ml-auto text-sm" />
        </div>
        <desktop-filters />
      </div>

      <div
        class="w-full md:w-2/3 md:pl-6 lg:w-3/4 lg:px-8"
      >
        <div class="flex flex-wrap overflow-hidden lg:-mx-8">
          <div v-for="hit in hits" :key="hit.id" class="w-full lg:px-8">
            <div class="w-full pt-6 lg:pt-4">
              <list-hit :hit="hit" />
              <div class="pt-5">
                <hr class="hr" />
              </div>
            </div>
          </div>
          <no-results v-if="!hits.length" />
        </div>

        <div class="mx-auto lg:w-1/2">
          <pagination />
        </div>
      </div>

      <hr class="hr" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import DesktopFilters from "@/search/components/filters/Desktop.vue";
import ListHit from "@/search/components/results/ListHit.vue";
import MobileFilterButton from "@/search/components/filters/MobileFilterButton.vue";
import NoResults from "@/search/components/results/NoResults.vue";
import Pagination from "@/search/components/pagination/Full.vue";
import PerPage from "@/search/components/results/PerPage.vue";
import ResetAllFilters from "@/search/components/filters/ResetAllFilters.vue";
import ResultCount from "@/search/components/results/ResultCount.vue";

export default {
  components: {
    DesktopFilters,
    ListHit,
    MobileFilterButton,
    NoResults,
    Pagination,
    PerPage,
    ResetAllFilters,
    ResultCount,
  },
  computed: {
    ...mapGetters(["hits", "isDesktop", "isMobile", "totalHits"]),
    hasNoResults() {
      return this.totalHits === 0;
    },
  },
};
</script>

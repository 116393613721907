<script>
import trackingHelper from "@/search/mixins/trackingHelper";

import HitImage from "@/search/components/results/HitImage.vue";
import HitMeta from "@/search/components/results/HitMeta.vue";

// function isObject(item) {
//   return typeof item === "object" && !Array.isArray(item) && item !== null;
// }

export default {
  components: {
    HitImage,
    HitMeta,
  },
  filters: {
    truncate(summary, length, suffix) {
      if (summary && summary.length > length) {
        return summary.substring(0, length) + suffix;
      }
      return summary;
    },
  },
  mixins: [trackingHelper],
  props: {
    hit: {
      required: true,
      type: Object,
    },
  },
  computed: {
    hasMeta() {
      return true;
    },
  },
};
</script>

import Vue from "vue";
import MonthYearSelect from "./MonthYearSelect.vue";

export const monthYearField = (configs) =>
  configs.map(
    ({ el, ...props }) =>
      new Vue({
        el,
        name: "MonthYearSelectWrapper",
        render(h) {
          return h(MonthYearSelect, {
            props,
          });
        },
      })
  );

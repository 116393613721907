import Vue from "vue";

import {
  SET_SEARCH_SHOWN,
  SET_PAGE,
  TOGGLE_SEARCH_FACET_VALUE,
  CLEAR_FACET,
} from "@/search/store/mutations";

import { mapGetters, mapState } from "vuex";

import trackingHelper from "@/search/mixins/trackingHelper";

const TrackingPlugin = Vue.extend({
  mixins: [trackingHelper],
  computed: {
    ...mapGetters(["pageForUi", "query", "totalHits"]),
    ...mapState({
      facets: ({ search }) => search.facets,
    }),
  },
  watch: {
    query(newQuery, oldQuery) {
      if (newQuery !== oldQuery) {
        this.trackQuery(newQuery);
      }
    },
    totalHits(total) {
      if (total === 0) {
        this.trackNoResults(this.query);
      }
    },
  },
  created() {
    this.$store.subscribe(this.mutationHandler);
  },
  methods: {
    isFacetApplied({ name, value }) {
      return this.facets[name].indexOf(value) > -1;
    },
    mutationHandler({ type, payload }) {
      let result;
      switch (type) {
        // show / hide search
        case SET_SEARCH_SHOWN:
          result = payload ? this.trackShowSearch() : this.trackHideSearch();
          break;
        // change page
        case SET_PAGE:
          result = this.trackGoResultPage(this.pageForUi);
          break;
        // facets
        case TOGGLE_SEARCH_FACET_VALUE:
          result = this.isFacetApplied(payload)
            ? this.trackAddFacet(this.getFacetLabel(payload))
            : this.trackRemoveFacet(this.getFacetLabel(payload));
          break;
        case CLEAR_FACET:
          result = this.trackRemoveFacet(this.getFacetLabel(payload));
          break;
        default:
          break;
      }
      return result;
    },
    getFacetLabel: ({ name, value }) => `${name}: ${value}`,
  },
});

export default function(store) {
  /* eslint-disable no-param-reassign */
  store.trackingPlugin = new TrackingPlugin({ store });
}

<template>
  <!-- eslint-disable vue/no-v-html -->
  <button
    type="button"
    :aria-label="resultsShown ? 'Hide search' : 'Show search'"
    :class="classes"
    @click.prevent="toggleSearch"
    v-html="resultsShown ? expandedText : collapsedText"
  />
</template>
<script>
import Vue from "vue";
import { mapState } from "vuex";

import { SET_RESULTS_SHOWN } from "@/search/store/mutations";

import { scrollToTarget } from "@/search/helpers/scroll-to-helper";

export default {
  props: {
    collapsedText: {
      type: [String, Element, Node],
      required: true,
    },
    expandedText: {
      type: [String, Element, Node],
      required: false,
      default: "Hide search results",
    },
    classes: {
      type: [String, Array],
      default: () => [],
    },
    searchApp: {
      type: [Vue],
      required: true,
    },
  },
  computed: {
    ...mapState({
      resultsShown: ({ ui }) => ui.resultsShown,
    }),
  },
  methods: {
    toggleSearch() {
      this.$store.commit(SET_RESULTS_SHOWN, !this.resultsShown);
      this.$store.algoliaPlugin.search();
      this.scrollToSearchContainer();
    },
    scrollToSearchContainer() {
      this.$nextTick(() => scrollToTarget(this.searchApp.$el, {}));
    },
  },
};
</script>

import { findIndex } from "lodash";

export function setFacetValues(state, key, name, values) {
  const facetIndex = findIndex(state[key], { name });

  if (facetIndex >= 0) {
    /* eslint-disable no-param-reassign */
    state[key][facetIndex].values = values;
  }
}

export function facetsForSearch(facets) {
  return facets.reduce((acc, facet) => {
    acc[facet.name] = [];
    return acc;
  }, {});
}

export default {
  setFacetValues,
  facetsForSearch,
};

<script>
import { mapActions, mapGetters } from "vuex";
import Icon from "@/components/Icon.vue";

export default {
  components: {
    Icon,
  },
  computed: {
    ...mapGetters(["hasNextPage"]),
  },
  methods: {
    ...mapActions(["pageNext"]),
  },
};
</script>

<template>
  <li class="pagination-next" :class="{ disabled: !hasNextPage }">
    <button
      :disabled="!hasNextPage"
      class="ml-auto p-3"
      aria-label="Next"
      @click.prevent="pageNext"
    >
      <icon icon="arrow" class="w-3 px-px text-beige" aria-hidden="true" />
    </button>
  </li>
</template>

<script>
import VueSlider from "vue-slider-component";

import "vue-slider-component/dist-css/vue-slider-component.css";

import { SET_RANGE_REFINEMENT } from "@/search/store/mutations";

// @todo abstract colours to palette
const brandRed = "#cc4527";

export default {
  components: {
    VueSlider,
  },
  props: {
    filter: {
      required: true,
      type: Object,
    },
    values: {
      required: true,
      type: Object,
    },
  },

  computed: {
    hasRefinements() {
      return !(this.values.min === this.min && this.values.max === this.max);
    },
    rangeConfig() {
      return {
        dotSize: 10,
        height: 2,
        speed: 1,
        lazy: true,
        min: this.filter.bounds.min,
        max: this.filter.bounds.max,
        contained: true,
        tooltip: "always",
        tooltipPlacement: "bottom",
        tooltipStyle: {
          backgroundColor: "#fff",
        },
        railStyle: {
          backgroundColor: "#ededed",
        },
        processStyle: {
          // backgroundColor: "#d1d1d1",
          backgroundColor: brandRed,
        },
        dotStyle: {
          borderColor: brandRed,
          backgroundColor: "#ffffff",
          borderWidth: "2px",
          borderRadius: "10px",
          height: "7px",
        },
      };
    },
    sliderValues: {
      get() {
        return [this.values.min, this.values.max];
      },
      set(newValues) {
        this.$store.commit(SET_RANGE_REFINEMENT, {
          name: this.filter.name,
          values: newValues,
        });
      },
    },
  },
  methods: {
    reset() {
      this.$store.commit(SET_RANGE_REFINEMENT, {
        name: this.filter.name,
        values: [this.filter.bounds.min, this.filter.bounds.max],
      });
    },
  },
};
</script>
<template>
  <fieldset class="mb-14">
    <legend class="hidden">{{ filter.displayTitle }}</legend>
    <div class="flex items-center mb-2">
      <h5 class="h-trim">{{ filter.displayTitle }}</h5>
      <button
        class="text-sm ml-auto"
        :class="{ '': hasRefinements }"
        :disabled="!hasRefinements"
        @click.prevent="reset"
      >
        Reset
      </button>
    </div>
    <vue-slider v-model="sliderValues" v-bind="rangeConfig" />
  </fieldset>
</template>

<template>
  <div>
    <country-select
      :resource="urls.international"
      :initial-value="selectedCountries"
      label="Select one or more countries or regions <span title='(this field is required)'>*</span>"
      @change="setCountries"
    />
    <locality-select
      v-for="country in selectedCountries"
      :key="country.id"
      :initial-value="initialiseLocalities(country)"
      :resource="urls.regions"
      :parent="country"
      :label="'Optionally select one or more localities in ' + country.title"
      @change="setLocalities"
    />
    <hidden-form-output :locations="combinedLocations" :name="name" />
  </div>
</template>

<script>
import CountrySelect from "./CountrySelect.vue";
import LocalitySelect from "./LocalitySelect.vue";
import HiddenFormOutput from "./HiddenFormOutput.vue";

export default {
  components: {
    CountrySelect,
    LocalitySelect,
    HiddenFormOutput,
  },
  props: {
    urls: {
      type: Object,
      required: true,
    },
    initialValue: {
      type: Array,
      default: () => [],
    },
    name: {
      type: String,
      required: true,
    },
    countriesLevel: {
      type: Number,
      default: 3,
    },
    localityLevel: {
      type: Number,
      default: 4,
    },
  },
  data() {
    return {
      selectedCountries: [],
      selectedLocalities: [],
    };
  },
  computed: {
    combinedLocations() {
      return this.selectedCountries.concat(this.selectedLocalities);
    },
  },
  created() {
    this.intialiseValues(this.initialValue);
  },
  methods: {
    initialiseLocalities(country) {
      return this.initialValue.filter((loc) => loc.parentId === country.id);
    },
    setCountries(countries) {
      this.selectedCountries = countries;
    },
    setLocalities(localities) {
      localities.forEach((locality) => {
        if (!this.selectedLocalities.find((l) => l.id === locality.id)) {
          this.selectedLocalities.push(locality);
        }
      });
    },
    intialiseValues(values) {
      this.selectedCountries = values.filter((v) => parseInt(v.level, 10) === this.countriesLevel);
    },
  },
};
</script>

<template>
  <div hidden>
    <input
      v-for="location in locations"
      :key="location.id"
      :name="name"
      :value="location.id"
      type="hidden"
    />
  </div>
</template>

<script>
export default {
  props: {
    locations: {
      type: Array,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
  },
};
</script>

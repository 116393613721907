// UI
export const SET_ALGOLIA_READY = "SET_ALGOLIA_READY";
export const SET_BREAKPOINT = "SET_BREAKPOINT";
export const SET_CONFIG = "SET_CONFIG";
export const SET_FORCE_SEARCH_SHOWN = "SET_FORCE_SEARCH_SHOWN";
export const SET_HIT_UI = "SET_HIT_UI";
export const SET_QUERY_FOCUS = "SET_QUERY_FOCUS";
export const SET_RESULTS_SHOWN = "SET_RESULTS_SHOWN";
export const SET_SEARCH_SHOWN = "SET_SEARCH_SHOWN";
export const STORE_QS = "STORE_QS";
export const TOGGLE_MOBILE_FILTERS = "TOGGLE_MOBILE_FILTERS";

// Search
export const SET_ALGOLIA_CONFIG = "SET_ALGOLIA_CONFIG";
export const SET_INDEX_NAME = "SET_INDEX_NAME";
export const SET_PAGE = "SET_PAGE";
export const SET_HITS_PER_PAGE = "SET_HITS_PER_PAGE";
export const SET_QUERY = "SET_QUERY";
export const SET_LOCATIONS_QUERY = "SET_LOCATIONS_QUERY";
export const SET_SEARCH_FACET_VALUE = "SET_SEARCH_FACET_VALUE";
export const TOGGLE_SEARCH_FACET_VALUE = "TOGGLE_SEARCH_FACET_VALUE";
export const UNSET_SEARCH_FACET_VALUE = "UNSET_SEARCH_FACET_VALUE";

// Results
export const SET_RESULTS = "SET_RESULTS";
// export const SET_SEARCH_STATE = 'SET_SEARCH_STATE';

// Facets
export const CLEAR_SEARCH = "CLEAR_SEARCH";
export const CLEAR_FACET = "CLEAR_FACET";
export const SET_DISJUNCTIVE_VALUES = "SET_DISJUNCTIVE_VALUES";
export const SET_FACET_VALUES = "SET_FACET_VALUES";
export const SET_NUMERIC_RANGE_VALUE = "SET_NUMERIC_RANGE_VALUE";
export const SET_NUMERIC_REFINEMENT_VALUE = "SET_NUMERIC_REFINEMENT_VALUE";
export const SET_RANGE_REFINEMENT = "SET_RANGE_REFINEMENT";

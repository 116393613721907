<template>
  <button
    :disabled="!hasRefinements"
    class="font-bold text-sm"
    :class="[activeClass]"
    @click.prevent="resetAll"
  >
    {{ title }}
  </button>
</template>

<script>
import { mapGetters } from "vuex";

import { CLEAR_SEARCH } from "@/search/store/mutations";

export default {
  props: {
    activeClassName: {
      type: String,
      default: "text-red",
    },
    title: {
      type: String,
      default: "Reset all",
    },
  },
  computed: {
    ...mapGetters(["hasRefinements"]),
    activeClass() {
      return this.hasRefinements ? this.activeClassName : "";
    },
  },
  methods: {
    resetAll() {
      this.$store.commit(CLEAR_SEARCH);
    },
  },
};
</script>

<script>
import { mapActions, mapGetters } from "vuex";
import Icon from "@/components/Icon.vue";

export default {
  components: {
    Icon,
  },
  computed: {
    ...mapGetters(["hasPrevPage"]),
  },
  methods: {
    ...mapActions(["pagePrev"]),
  },
};
</script>

<template>
  <li :class="{ disabled: !hasPrevPage }">
    <button
      href="#"
      class="mr-auto p-3"
      :disabled="!hasPrevPage"
      aria-label="Previous"
      @click.prevent="pagePrev"
    >
      <icon icon="arrow-back" class="w-3 px-px text-beige" aria-hidden="true" />
    </button>
  </li>
</template>

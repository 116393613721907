<script>
import { mapGetters } from "vuex";

import stringHelper from "@/search/mixins/stringHelper";
import Filters from "@/search/components/filters/All.vue";

export default {
  components: {
    Filters,
  },
  mixins: [stringHelper],
  computed: {
    ...mapGetters(["isDesktop"]),
  },
};
</script>

<template>
  <filters />
</template>

import { partial, debounce, isUndefined, isNull } from "lodash";

import algoliasearch from "algoliasearch/lite";
import algoliasearchHelper from "algoliasearch-helper";
import Vue from "vue";
import { createBrowserHistory } from "history";
import qs from "qs";
import { mapActions, mapGetters, mapState } from "vuex";

import findOne from "@/lib/findOne";

import {
  SET_ALGOLIA_READY,
  SET_DISJUNCTIVE_VALUES,
  SET_NUMERIC_RANGE_VALUE,
  SET_QUERY,
  SET_RESULTS_SHOWN,
  SET_SEARCH_SHOWN,
} from "@/search/store/mutations";

import { FACETS, DISJUNCTIVE_FACETS, NUMERIC_RANGE_REFINEMENTS } from "@/search/constants/facets";

function mapFacetsState(facets) {
  return facets.reduce((acc, f) => {
    acc[f.name] = (state) => state.search.facets[f.name];
    return acc;
  }, {});
}

// executed bound to $vm instance
function FacetWatcherFactory(f, { addMethod }) {
  return function watchFacet(newValues) {
    if (this.isEnabled) {
      this.helper.clearRefinements(f.name);

      if (Array.isArray(newValues)) {
        newValues.forEach((v) => this.helper[addMethod](f.name, v));
      }

      this.search();
    }
  };
}

function RangeWatcherFactory(f) {
  return function watchRangeFacet(rangeValues) {
    if (this.isEnabled) {
      this.helper.clearRefinements(f.name);

      this.helper.addNumericRefinement(f.name, ">=", rangeValues[0]);
      this.helper.addNumericRefinement(f.name, "<=", rangeValues[1]);

      this.search();
    }
  };
}

function mapWatchers(factory, facets, opts) {
  return facets.reduce((acc, facet) => {
    acc[facet.name] = factory(facet, opts);

    return acc;
  }, {});
}

const mapFacetWatchers = partial(mapWatchers, FacetWatcherFactory);
const mapRangeFacetWatchers = partial(mapWatchers, RangeWatcherFactory);
const mapSearchFacetWatchers = (facets) =>
  facets.reduce((acc, facet) => {
    acc[facet.queryStateKey] = function watchFacetQuery(newQuery) {
      this.helper.searchForFacetValues(facet.name, newQuery).then(({ facetHits }) => {
        this.setFacetValues({
          name: facet.name,
          values: facetHits,
        });
      });
    };

    return acc;
  }, {});

const AlgoliaPlugin = Vue.extend({
  data() {
    return {
      client: null,
      helper: null,
      searchDebounceAmount: 60,
      // is the adaptor watching vuex state yet?
      history: createBrowserHistory(),
      ignoreQsParams: [
        "sep",
        "utm_source",
        "utm_medium",
        "utm_campaign",
        "utm_term",
        "utm_content",
      ],
    };
  },
  computed: {
    ...mapState({
      resultsShown: ({ ui }) => ui.resultsShown,
      searchShown: ({ ui }) => ui.searchShown,
      indexName: ({ search }) => search.indexName,
      isEnabled: ({ search }) => search.isEnabled,
      locationsQuery: ({ search }) => search.locationsQuery,
      algoliaReady: ({ ui }) => ui.algoliaReady,
      storedQs: ({ ui }) => ui.storedQs,
      APPLICATION_ID: ({ search }) => search.config.APPLICATION_ID,
      SEARCH_API_KEY: ({ search }) => search.config.SEARCH_API_KEY,
      ...mapFacetsState(FACETS),
      ...mapFacetsState(DISJUNCTIVE_FACETS),
      ...mapFacetsState(NUMERIC_RANGE_REFINEMENTS),
    }),
    ...mapGetters(["query", "page", "params", "perPage", "showSearchOnInit"]),
  },
  watch: {
    indexName() {
      this.initClient();
    },
    SEARCH_API_KEY() {
      if (!this.algoliaReady) {
        this.initClient();
      }
    },
    query(newValue) {
      if (this.isEnabled && newValue !== this.helper.state.query) {
        this.helper.setQuery(newValue);
        this.search();
      }
    },
    perPage(newValue) {
      if (this.isEnabled && newValue !== this.helper.state.hitsPerPage) {
        this.helper.setQueryParameter("hitsPerPage", newValue);
        this.search();
      }
    },
    page(page) {
      if (this.isEnabled && !isNull(page)) {
        this.helper.setPage(page);
        this.search();
      }
    },
    resultsShown(nowShown) {
      if (!nowShown) {
        this.removeQsState();
      } else if (this.resultsShown) {
        this.updateQsState();
      }
    },
    ...mapFacetWatchers(FACETS, {
      addMethod: "addFacetRefinement",
    }),
    ...mapFacetWatchers(DISJUNCTIVE_FACETS, {
      addMethod: "addDisjunctiveFacetRefinement",
    }),
    ...mapSearchFacetWatchers([...FACETS, ...DISJUNCTIVE_FACETS].filter((f) => f.searchable)),
    ...mapRangeFacetWatchers(NUMERIC_RANGE_REFINEMENTS),
  },
  created() {
    if (this.APPLICATION_ID && this.SEARCH_API_KEY) {
      this.initClient();
    }
  },
  methods: {
    ...mapActions(["setFacetValues", "setNumericRefinement"]),
    initClient() {
      this.client = algoliasearch(this.APPLICATION_ID, this.SEARCH_API_KEY);

      this.helper = algoliasearchHelper(this.client, this.indexName, this.params);

      this.helper.on("result", this.handleResults);

      // this.helper.addDisjunctiveFacetRefinement("status", "live");

      this.search = debounce(() => {
        this.helper.search();
      }, this.searchDebounceAmount);

      if (this.loadQsState()) {
        this.search();
        this.$store.commit(SET_SEARCH_SHOWN, true);
      }

      this.$store.commit(SET_ALGOLIA_READY, true);
    },
    search() {},
    fetchById(objectID) {
      const $vm = this;

      const fetchObject = (id) => $vm.client.initIndex($vm.indexName).getObject(id);

      return new Promise((r) => {
        if ($vm.algoliaReady) {
          fetchObject(objectID).then((o) => r(o));
        } else {
          $vm.$watch("algoliaReady", () => {
            fetchObject(objectID).then((o) => r(o));
          });
        }
      });
    },
    handleResults(result) {
      this.$store
        .dispatch("setResults", {
          hits: result.hits,
          nbHits: result.nbHits,
          page: result.page,
          nbPages: result.nbPages,
        })
        .then(() => {
          this.$store.commit(SET_QUERY, result.query);
          // result.facets.map((f) => this.setFacetValues(this.prepFacetValues(f, result)));
          result.disjunctiveFacets.map((f) => this.setFacetValues(this.prepFacetValues(f, result)));
          result.getRefinements().map((r) => this.setRefinement(r));

          if (this.resultsShown) {
            this.updateQsState();
          }
        });
    },
    loadQsState() {
      const qString = this.history.location.search.replace(/^\?/, "");
      let didLoadFromQs = false;

      const qObj = qs.parse(qString);

      if (!this.showSearchOnInit || findOne(Object.keys(qObj), this.ignoreQsParams)) {
        return false;
      }
      if (qString.length) {
        const state = algoliasearchHelper.url.getStateFromQueryString(qString);

        this.helper.setState(state);

        // we need to directly set the numeric refinement min / max values here,
        // otherwise they get overwritten by the search that gets triggered immediately
        // after the filters are initted, due to the default values of the numeric
        // filters being likely different from those in the QS

        if (!isUndefined(state.numericRefinements)) {
          this.setNumericRefinements(state);
        }

        this.$store.commit(SET_RESULTS_SHOWN, true);

        didLoadFromQs = true;
      }
      return didLoadFromQs;
    },
    updateQsState() {
      const state = this.helper.getState();

      this.history.replace({
        search: `?${algoliasearchHelper.url.getQueryStringFromState(state)}`,
        hash: window.location.hash,
      });
    },
    removeQsState() {
      this.history.replace({
        search: `?${this.storedQs}`,
        hash: window.location.hash,
      });
    },
    setNumericRefinements(state) {
      NUMERIC_RANGE_REFINEMENTS.forEach(({ name }) => {
        const refinement = state.numericRefinements[name];
        if (refinement) {
          Object.keys(refinement).forEach((operator) => {
            const value = refinement[operator][0];

            switch (operator) {
              case ">=":
                this.$store.commit(SET_NUMERIC_RANGE_VALUE, {
                  name,
                  value,
                  type: "min",
                });
                break;
              case "<=":
                this.$store.commit(SET_NUMERIC_RANGE_VALUE, {
                  name,
                  value,
                  type: "max",
                });
                break;
              default:
                break;
            }
          });
        }
      });
    },
    setRefinement(refinement) {
      switch (refinement.type) {
        case "numeric":
          this.setNumericRefinement(refinement);
          break;
        default:
          break;
      }
    },
    prepFacetValues(facet, result) {
      return {
        name: facet.name,
        values: result.getFacetValues(facet.name, { sortBy: ["count:desc"] }),
      };
    },
  },
});

export default function(store) {
  /* eslint-disable no-param-reassign */
  store.algoliaPlugin = new AlgoliaPlugin({ store });
}

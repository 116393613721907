import enquire from "enquire.js";
import Vue from "vue";

import BREAKPOINTS from "@/search/constants/breakpoints";

import { SET_BREAKPOINT } from "@/search/store/mutations";

const BreakpointPlugin = Vue.extend({
  data() {
    return {
      breakpoints: BREAKPOINTS,
    };
  },
  created() {
    Object.entries(this.breakpoints).forEach(([name, px]) => {
      this.registerBreakpoint(name, px);
    });
  },
  methods: {
    registerBreakpoint(name, px) {
      const $vm = this;
      const ems = px / 16;
      const breakpoint = `(min-width: ${ems}em)`;
      const { matches } = window.matchMedia(breakpoint);

      // set initial state
      this.$store.commit(SET_BREAKPOINT, { name, matches });
      // track changes
      enquire.register(breakpoint, {
        match() {
          $vm.$store.commit(SET_BREAKPOINT, { name, matches: true });
        },
        unmatch() {
          $vm.$store.commit(SET_BREAKPOINT, { name, matches: false });
        },
      });
    },
  },
});

export default function(store) {
  /* eslint-disable no-param-reassign */
  store.breakpointPlugin = new BreakpointPlugin({ store });
}

<template>
  <div class="text-sm">
    Results per page:&nbsp;
    <button
      v-for="value in options"
      :key="value"
      :class="{ 'text-black': isActive(value) }"
      class="text-sm font-medium text-red px-1"
      @click.prevent="setPerPage(value)"
    >
      {{ value }}
    </button>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

import { SET_HITS_PER_PAGE } from "@/search/store/mutations";

export default {
  computed: {
    ...mapGetters(["perPage", "totalHits"]),
    ...mapState({
      options: ({ ui }) => ui.resultsPerPageOptions,
    }),
  },
  methods: {
    setPerPage(value) {
      this.$store.commit(SET_HITS_PER_PAGE, value);
    },
    isActive(value) {
      return value === this.perPage;
    },
  },
};
</script>

import Vue from "vue";
import Vuex from "vuex";

// Plugins
import algolia from "./plugins/algolia";
import breakpoint from "./plugins/breakpoint";
import eventTracking from "./plugins/eventTracking";

// Modules
import facets from "./modules/facets";
import search from "./modules/search";
import ui from "./modules/ui";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    facets,
    search,
    ui,
  },
  plugins: [algolia, breakpoint, eventTracking],
});

<template>
  <div class="">
    No results found for that query.
    <reset-all-filters
      class=""
      active-class-name=""
      title="Try clearing some filters"
    ></reset-all-filters
    >.
  </div>
</template>

<script>
import ResetAllFilters from "@/search/components/filters/ResetAllFilters.vue";

export default {
  components: {
    ResetAllFilters,
  },
};
</script>

<template>
  <div class="control mb-12">
    <label class="label " for="countries" v:html="label"></label>
    <multiselect
      id="countries"
      v-model="selected"
      :clear-on-select="true"
      :close-on-select="false"
      :hide-selected="true"
      :internal-search="false"
      :limit-text="limitText"
      :limit="3"
      :loading="isLoading"
      :max-height="600"
      :multiple="true"
      :options-limit="300"
      :options="options"
      :searchable="true"
      label="title"
      open-direction="bottom"
      placeholder="Type to search countries"
      track-by="title"
      @search-change="asyncFindCountry"
    >
      <template slot="tag" slot-scope="{ option, remove }">
        <span class="multiselect__tag">
          <span>{{ option.title }}</span>
          <button
            type="button"
            class="multiselect__remove"
            title="remove option"
            @click="remove(option)"
          >
            ×
          </button>
        </span>
      </template>
      <template slot="clear" slot-scope="props">
        <div
          v-if="selected.length"
          class="multiselect__clear"
          @mousedown.prevent.stop="clearAll(props.search)"
        ></div>
      </template>
      <span slot="noResult">No matches found</span>
      <span slot="noOptions">
        <template v-if="!query">
          Please enter a search query
        </template>
      </span>
    </multiselect>
  </div>
</template>
<script>
import Multiselect from "vue-multiselect";
import { ajaxFindLocality } from "./countriesApi";

export default {
  components: {
    Multiselect,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    resource: {
      type: String,
      required: true,
    },
    initialValue: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      query: "",
      minQueryLength: 1,
      selected: [],
      options: [],
      isLoading: false,
    };
  },
  watch: {
    selected(nowSelected) {
      this.options = [];
      this.$emit("change", nowSelected);
    },
  },
  created() {
    this.selected = [...this.initialValue];
  },
  methods: {
    limitText(count) {
      return `and ${count} other countries`;
    },
    async asyncFindCountry(query) {
      this.query = query;

      if (query.length >= this.minQueryLength) {
        this.isLoading = true;
        this.options = [];
        this.options = await ajaxFindLocality({ query, url: this.resource });
        this.isLoading = false;
      }
    },
    clearAll() {
      this.selected = [];
    },
  },
};
</script>

<template>
  <div>
    <div class="px-8 bg-white transition-slow" :class="{ 'opacity-75': resultsShown }">
      <div class="flex flex-wrap items-center border-b border-grey-light">
        <div class="flex-1">
          <query-input class="" @change="showResults"></query-input>
        </div>
        <div class="h-5 md:h-6" :class="!resultsShown ? 'w-5 md:w-6' : 'w-auto'">
          <icon v-if="!resultsShown" icon="search" class="text-red" />
          <button v-else class="mt-px font-bold text-right text-black" @click="setExampleQuery('')">
            Clear
          </button>
        </div>
      </div>

      <div v-if="!resultsShown" class="pt-3 pb-4 transition-slow">
        <span class="text-sm font-medium md:text-base">
          Try these quick searches:
          <span v-for="(exampleQuery, index) in exampleQueries" :key="query + index">
            <button
              class="font-medium underline text-red hover:text-coral focus:text-coral"
              type="button"
              @click.prevent="setExampleQuery(exampleQuery)"
            >
              {{ exampleQuery }}
            </button>
            <template v-if="index < exampleQueries.length - 1"
              >,
            </template>
          </span>
        </span>
      </div>

      <div v-if="!isDesktop">
        <mobile-filter-button></mobile-filter-button>
      </div>
    </div>

    <div class="flex">
      <button
        v-if="resultsShown"
        class="mt-3 ml-auto font-bold text-right text-white link--close link--close--white"
        @click="hideResults()"
      >
        Close Search
      </button>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

import { SET_RESULTS_SHOWN, SET_QUERY_FOCUS, SET_QUERY } from "@/search/store/mutations";

import MobileFilterButton from "@/search/components/filters/MobileFilterButton.vue";
import QueryInput from "@/search/components/query/QueryInput.vue";
import Icon from "@/components/Icon.vue";

export default {
  components: {
    QueryInput,
    Icon,
    MobileFilterButton,
  },
  data() {
    return {
      exampleQueries: ["Syria", "Archaeology", "Documentation", "Training"],
    };
  },
  computed: {
    ...mapGetters(["hits", "isDesktop", "isMobile", "totalHits"]),
    ...mapState({
      resultsShown: ({ ui }) => ui.resultsShown,
      query: ({ search }) => search.query,
    }),
    ...mapGetters(["hasSearched", "isMobile"]),
  },
  methods: {
    setExampleQuery(query) {
      this.$store.commit(SET_QUERY, query);
      this.showResults();
    },
    showResults() {
      if (!this.resultsShown && this.query.length) {
        this.$store.commit(SET_RESULTS_SHOWN, true);
      }
    },
    hideResults() {
      this.$store.commit(SET_RESULTS_SHOWN, false);
    },
    onFocus() {
      this.$store.commit(SET_QUERY_FOCUS, true);
    },
    onBlur() {
      this.$store.commit(SET_QUERY_FOCUS, false);
    },
  },
};
</script>

export function toggleArrayValue(array, value) {
  const index = array.indexOf(value);

  if (index === -1) {
    array.push(value);
  } else {
    array.splice(index, 1);
  }
}

export function addUniqueArrayValue(array, value) {
  const index = array.indexOf(value);

  if (index === -1) {
    array.push(value);
  }
}

export function rmUniqueArrayValue(array, value) {
  const index = array.indexOf(value);

  if (index !== -1) {
    array.splice(index, 1);
  }
}

export default {
  toggleArrayValue,
  addUniqueArrayValue,
  rmUniqueArrayValue,
};

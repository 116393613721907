<template>
  <fieldset v-if="filter.values.length || filter.searchable" class="mb-6 md:mb-8">
    <legend class="hidden">{{ filter.displayTitle }}</legend>
    <div class="flex items-center mb-2">
      <h5 class="h-trim">
        {{ filter.displayTitle }}
      </h5>
      <button
        class="text-sm ml-auto"
        :class="{ 'text-red': hasRefinements }"
        :title="'Reset ' + filter.displayTitle"
        :disabled="!hasRefinements"
        @click.prevent="reset"
      >
        Reset
      </button>
    </div>
    <slot name="before-checkboxes"></slot>
    <ul v-if="showOptions">
      <li v-for="value in filterValues" :key="value.name" class="mb-2">
        <div class="checkbox">
          <input
            :id="getFilterValueId(value.name)"
            type="checkbox"
            class=""
            :aria-labelledby="getFilterValueId(value.name)"
            :value="value.name"
            :checked="value.isRefined"
            @change="toggleValue(value)"
          />
          <label :for="getFilterValueId(value.name)">
            {{ capitalize(value.name) }}
            <span class="text-grey-warm">({{ value.count }})</span>
          </label>
        </div>
      </li>
    </ul>
  </fieldset>
</template>
<script>
import slugify from "underscore.string/slugify";
import capitalize from "underscore.string/capitalize";

import { mapState } from "vuex";

import { TOGGLE_SEARCH_FACET_VALUE, CLEAR_FACET } from "@/search/store/mutations";

export default {
  props: {
    filter: {
      required: true,
      type: Object,
    },
    showOptions: {
      type: Boolean,
      default: true,
      required: false,
    },
  },
  computed: {
    ...mapState({
      maxValuesPerFacet: ({ search }) => search.params.maxValuesPerFacet,
    }),
    hasRefinements() {
      return this.filter.values.filter((v) => v.isRefined).length > 0;
    },
    filterValues() {
      // sort refined first
      return [...this.filter.values]
        .sort((x, y) => Number(!!y.isRefined) - Number(!!x.isRefined))
        .slice(0, this.maxValuesPerFacet);
    },
  },
  methods: {
    reset() {
      this.$store.commit(CLEAR_FACET, this.filter.name);
      this.$emit("reset-filter");
    },
    getFilterValueId(valueName) {
      return slugify(`${this.filter.name}-${valueName}`);
    },
    capitalize: (str) => capitalize(str),
    toggleValue(value) {
      this.$store.commit(TOGGLE_SEARCH_FACET_VALUE, {
        name: this.filter.name,
        value: value.name,
      });
    },
  },
};
</script>

import Vue from "vue";
import FundersSelect from "./FundersSelect.vue";

export const fundersSelect = (configs) =>
  configs.map(
    ({ el, ...props }) =>
      new Vue({
        el,
        name: "FundersSelectWrapper",
        render(h) {
          return h(FundersSelect, {
            props,
          });
        },
      })
  );

<template>
  <div v-if="totalPages > 1" class="m-pagination">
    <nav>
      <ul class="flex flex-wrap items-center justify-center">
        <page-prev></page-prev>
        <li v-for="page in pageArray" :key="page" class="block">
          <a
            href="#"
            :class="[getActiveClass(page)]"
            class="px-1 font-medium"
            @click.prevent="setPage({ page: page - 1 })"
          >
            {{ page }}
          </a>
        </li>
        <page-next></page-next>
      </ul>
    </nav>
  </div>
</template>

<script>
import _ from "lodash";

import { mapGetters, mapActions } from "vuex";

import PageNext from "@/search/components/pagination/PageNext.vue";
import PagePrev from "@/search/components/pagination/PagePrev.vue";

export default {
  components: {
    PageNext,
    PagePrev,
  },
  data() {
    return {
      activeClass: "text-red underline",
    };
  },
  computed: {
    ...mapGetters(["pageForUi", "totalPages"]),
    pageArray() {
      return _.range(this.lowerBound, this.upperBound);
    },
    lowerBound() {
      let lowerBound;

      if (this.totalPages < 7) {
        lowerBound = 1;
      } else {
        lowerBound = this.pageForUi - 3;
      }

      lowerBound = lowerBound >= 1 ? lowerBound : 1;

      return lowerBound;
    },
    upperBound() {
      let upperBound = this.lowerBound + 7;

      upperBound = upperBound <= this.totalPages + 1 ? upperBound : this.totalPages + 1;
      return upperBound;
    },
  },
  methods: {
    ...mapActions(["pagePrev", "pageNext", "setPage"]),
    getActiveClass(page) {
      return page === this.pageForUi ? this.activeClass : "";
    },
  },
};
</script>

/* eslint-disable no-param-reassign */
import {
  CLEAR_FACET,
  CLEAR_SEARCH,
  SET_ALGOLIA_CONFIG,
  SET_HITS_PER_PAGE,
  SET_INDEX_NAME,
  SET_LOCATIONS_QUERY,
  SET_PAGE,
  SET_QUERY,
  SET_RANGE_REFINEMENT,
  SET_RESULTS,
  SET_SEARCH_FACET_VALUE,
  TOGGLE_SEARCH_FACET_VALUE,
  UNSET_SEARCH_FACET_VALUE,
} from "@/search/store/mutations";

import { FACETS, NUMERIC_RANGE_REFINEMENTS, DISJUNCTIVE_FACETS } from "@/search/constants/facets";

import {
  toggleArrayValue,
  addUniqueArrayValue,
  rmUniqueArrayValue,
} from "@/search/store/helpers/arrayHelper";

import { facetsForSearch } from "@/search/store/helpers/facetHelper";

function rangeFilterForSearch(facets) {
  return facets.reduce((acc, facet) => {
    acc[facet.name] = {
      min: null,
      max: null,
    };
    return acc;
  }, {});
}

const facets = Object.assign(
  {},
  facetsForSearch(FACETS),
  rangeFilterForSearch(NUMERIC_RANGE_REFINEMENTS),
  facetsForSearch(DISJUNCTIVE_FACETS)
);

export default {
  state: {
    config: {},
    facets,
    hits: [],
    indexName: "",
    isEnabled: true,
    locationsQuery: "",
    nbHits: null,
    nbPages: null,
    page: null,
    params: {
      hitsPerPage: 10,
      maxValuesPerFacet: 10,
      maxFacetHits: 10,
      sortFacetValuesBy: "count",
      // getRankingInfo: true,
    },
    query: "",
  },
  mutations: {
    [SET_QUERY](state, query) {
      state.query = query;
    },
    [SET_LOCATIONS_QUERY](state, locationsQuery) {
      state.locationsQuery = locationsQuery;
    },
    [CLEAR_SEARCH](state) {
      const arrayFacets = FACETS.concat(DISJUNCTIVE_FACETS);

      state.query = "";

      arrayFacets.forEach(({ name }) => {
        state.facets[name] = [];
      });

      NUMERIC_RANGE_REFINEMENTS.forEach(({ name, bounds }) => {
        state.facets[name] = [bounds.min, bounds.max];
      });
    },
    [SET_INDEX_NAME](state, indexName) {
      state.indexName = indexName;
    },
    [SET_ALGOLIA_CONFIG](state, config) {
      state.config = config;
    },
    [SET_PAGE](state, page) {
      state.page = parseInt(page, 10);
    },
    [SET_HITS_PER_PAGE](state, hitsPerPage) {
      state.params.hitsPerPage = parseInt(hitsPerPage, 10);
    },
    [SET_SEARCH_FACET_VALUE](state, { name, value }) {
      addUniqueArrayValue(state.facets[name], value);
    },
    [UNSET_SEARCH_FACET_VALUE](state, { name, value }) {
      rmUniqueArrayValue(state.facets[name], value);
    },
    [TOGGLE_SEARCH_FACET_VALUE](state, { name, value }) {
      toggleArrayValue(state.facets[name], value);
    },
    [SET_RANGE_REFINEMENT](state, { name, values }) {
      state.facets[name] = values;
    },
    [CLEAR_FACET](state, name) {
      state.facets[name] = [];
    },
    [SET_RESULTS](state, { hits, nbHits, page, nbPages }) {
      state.hits = hits;
      state.nbHits = nbHits;
      state.page = page;
      state.nbPages = nbPages;
    },
  },
  getters: {
    query: (state) => state.query,

    params: (state, getters) =>
      Object.assign(state.params, {
        facets: getters.facetsForAlgolia,
        disjunctiveFacets: getters.disjunctiveForAlgolia,
      }),

    hasSearched: (state) => {
      let hasSearched = false;

      if (state.query.length) {
        hasSearched = true;
      } else {
        Object.keys(state.facets).forEach((f) => {
          if (state.facets[f].length) {
            hasSearched = true;
          }
        });
      }
      return hasSearched;
    },
    hits: ({ hits }) => hits,
    hitsOnPage: ({ hits }) => hits.length,
    totalHits: ({ nbHits }) => nbHits,

    firstHitOnPage: (state, { page, perPage }) => 1 + page * perPage,
    lastHitOnPage: (state, { firstHitOnPage, hitsOnPage }) => firstHitOnPage + (hitsOnPage - 1),

    page: (state) => state.page,
    pageForUi: (state) => state.page + 1,
    perPage: (state) => state.params.hitsPerPage,
    totalPages: ({ nbPages }) => nbPages,
    hasPrevPage: (state, { pageForUi }) => pageForUi > 1,
    hasNextPage: (state, { pageForUi, totalPages }) => pageForUi < totalPages,

    hasRefinements: (state) => {
      let isRefined = false;

      const arrayFacets = FACETS.concat(DISJUNCTIVE_FACETS);

      if (state.query.length) {
        isRefined = true;
      } else {
        arrayFacets.forEach(({ name }) => {
          if (state.facets[name].length) {
            isRefined = true;
          }
        });

        NUMERIC_RANGE_REFINEMENTS.forEach(({ name, bounds }) => {
          const defaultValue = [bounds.min, bounds.max];
          const currentValue = state.facets[name];

          if (currentValue[0] !== defaultValue[0] || currentValue[1] !== defaultValue[1]) {
            isRefined = true;
          }
        });
      }

      return isRefined;
    },
  },
  actions: {
    preloadQuery({ state, commit }, config) {
      Object.entries(config).forEach(function mapPreloadQuery([name, value]) {
        if (state.facets[name]) {
          value.forEach((v) => commit(SET_SEARCH_FACET_VALUE, { name, value: v }));
        } else {
          let mutation;

          switch (name) {
            case "query":
              mutation = SET_QUERY;
              break;
            case "perPage":
              mutation = SET_HITS_PER_PAGE;
              break;
            case "page":
              mutation = SET_PAGE;
              break;
            default:
              break;
          }
          if (mutation && state[name] !== value) {
            commit(mutation, value);
          }
        }
      });
    },
    async setPage({ commit, rootGetters }, { page }) {
      page = parseInt(page, 10);

      if (page > rootGetters.totalPages - 1) {
        page = rootGetters.totalPages - 1;
      }
      if (page <= 0) {
        page = 0;
      }

      return commit(SET_PAGE, page);
    },
    async setResults({ commit }, payload) {
      commit(SET_RESULTS, payload);
      return true;
    },
    pageNext({ dispatch, state }) {
      dispatch("setPage", { page: state.page + 1 });
    },
    pagePrev({ dispatch, state }) {
      dispatch("setPage", { page: state.page - 1 });
    },
  },
};

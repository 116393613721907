import { debounce } from "lodash";

const debouceAmount = 1000;

export function track({ category = "search", action, label, value }) {
  let debugEvents = false;

  if (typeof window.APP !== "undefined" && window.APP.CONFIG) {
    debugEvents = !!window.APP.CONFIG.DEBUG_EVENTS;
  }

  const { dataLayer } = window;

  const eventData = {
    event: `${category}`,
    eventAction: action,
    eventCategory: category,
    eventLabel: label,
    eventValue: value,
  };

  if (dataLayer) {
    dataLayer.push(eventData);
  }

  if (debugEvents) {
    console.log("[track]", eventData);
  }
}

/**
 * Track search UI shown
 */
export function trackShowSearch() {
  track({
    action: "show",
  });
}

/**
 * Track search UI hidden
 */
export function trackHideSearch() {
  track({
    action: "hide",
  });
}

/**
 * Track search for query term
 */
export const trackQuery = debounce(function trackQuery(label) {
  track({
    action: "query",
    label,
  });
}, debouceAmount);

/*
 * Track no results for query term
 */
export const trackNoResults = debounce(function trackNoResults(label) {
  track({
    action: "noResults",
    label,
  });
}, debouceAmount);

/*
 * Track click / tap on meta info in search result
 */
export function trackGoMeta(label) {
  track({
    action: "clickSearchMeta",
    label,
  });
}

/*
 * Track click / tap through to result page
 */
export function trackGoResult(label) {
  track({
    action: "clickSearchResult",
    label,
  });
}

/*
 * Track change results page
 */
export function trackGoResultPage(value) {
  track({
    action: "changeResultsPage",
    value,
  });
}

/*
 * Track add facet
 */
export function trackAddFacet(label) {
  track({
    action: "facet.add",
    label,
  });
}

/*
 * Track remove facet
 */
export function trackRemoveFacet(label) {
  track({
    action: "facet.remove",
    label,
  });
}

export default {
  methods: {
    track,

    trackHideSearch,
    trackShowSearch,

    trackGoMeta,
    trackGoResult,
    trackGoResultPage,

    trackQuery,
    trackNoResults,

    trackAddFacet,
    trackRemoveFacet,
  },
};

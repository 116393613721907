import { find, orderBy, uniqBy } from "lodash";

import { FACETS, NUMERIC_RANGE_REFINEMENTS, DISJUNCTIVE_FACETS } from "@/search/constants/facets";

import {
  SET_FACET_VALUES,
  SET_DISJUNCTIVE_VALUES,
  SET_NUMERIC_REFINEMENT_VALUE,
  SET_NUMERIC_RANGE_VALUE,
  SET_SEARCH_FACET_VALUE,
} from "@/search/store/mutations";

import { setFacetValues } from "@/search/store/helpers/facetHelper";

export default {
  state: {
    facets: FACETS,
    numericRefinements: [],
    rangeRefinements: NUMERIC_RANGE_REFINEMENTS,
    disjunctiveFacets: DISJUNCTIVE_FACETS,
  },
  mutations: {
    [SET_FACET_VALUES](state, { name, values }) {
      setFacetValues(state, "facets", name, values);
    },
    [SET_DISJUNCTIVE_VALUES](state, { name, values }) {
      setFacetValues(state, "disjunctiveFacets", name, values);
    },
    [SET_NUMERIC_REFINEMENT_VALUE](state, { name, value }) {
      setFacetValues(state, "numericRefinements", name, value);
    },
    [SET_NUMERIC_RANGE_VALUE](state, { name, value, type }) {
      const refinement = find(state.rangeRefinements, { name });

      if (refinement) {
        refinement.values[type] = value;
      } else {
        throw new Error(`no refinement ${name}`);
      }
    },
  },
  getters: {
    filtersForUi({ facets, disjunctiveFacets, rangeRefinements }) {
      return orderBy(facets.concat(disjunctiveFacets).concat(rangeRefinements), "displayOrder");
    },
    facetsForAlgolia: ({ facets }) => facets.map((f) => f.name),
    disjunctiveForAlgolia: ({ disjunctiveFacets }) =>
      disjunctiveFacets.map((f) => f.name).concat(["status"]),
    rangesForAlgolia: ({ rangeRefinements }) =>
      rangeRefinements.map(({ name, values }) => ({ name, values })),
  },
  actions: {
    setFacetValues({ commit, state, rootState }, newFacet) {
      const { name, values: newValues } = newFacet;

      const {
        facets: facetRefinements,
        disjunctiveFacets: disjunctiveFacetRefinements,
      } = rootState.search;

      const currentRefinements = {
        ...facetRefinements,
        ...disjunctiveFacetRefinements,
      }[name];

      const refinedValues = [...state.facets, ...state.disjunctiveFacets]
        .map((f) => f.values)
        .flat()
        .filter((f) => currentRefinements.includes(f.value));

      const values = uniqBy(
        [...newValues, ...refinedValues].map((val) => {
          /* eslint-disable no-param-reassign */
          // @shame @hack needs refactoring out
          val.name = val.name || val.value;
          val.value = val.value || val.name;

          return val;
        }),
        "value"
      );

      const facet = {
        name,
        values,
      };

      commit(SET_DISJUNCTIVE_VALUES, facet);

      values
        .filter((val) => val.isRefined)
        .forEach((val) =>
          commit(SET_SEARCH_FACET_VALUE, {
            name: newFacet.name,
            value: val.name,
          })
        );
    },
    setNumericRefinement({ commit }, r) {
      switch (r.operator) {
        case "<=":
          commit(SET_NUMERIC_RANGE_VALUE, {
            name: r.attributeName,
            value: r.numericValue,
            type: "max",
          });
          break;
        case ">=":
          commit(SET_NUMERIC_RANGE_VALUE, {
            name: r.attributeName,
            value: r.numericValue,
            type: "min",
          });
          break;
        case "=":
        default:
          commit(SET_NUMERIC_REFINEMENT_VALUE, {
            name: r.attributeName,
            value: r.numericValue,
          });
          break;
      }
    },
  },
};

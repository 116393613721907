<template>
  <div>
    <label :for="id" class="sr-only">Search {{ filter.displayTitle }}</label>
    <input
      :id="id"
      v-model="query"
      class="input px-3 py-2 mb-4"
      type="search"
      placeholder="Enter location keywords"
      autocomplete="off"
      autocorrect="off"
      autocapitalize="off"
      spellcheck="true"
      @input="setStoreQuery"
    />
  </div>
</template>
<script>
import { debounce } from "lodash";
import { mapState } from "vuex";

export default {
  props: {
    filter: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      query: "",
    };
  },
  computed: {
    id() {
      return `search-${this.filter.name}`;
    },
    ...mapState({
      search: (state) => state.search,
    }),
  },
  created() {
    this.$watch(`search.${this.filter.queryStateKey}`, this.handleStoreQueryChange);
  },
  methods: {
    handleStoreQueryChange(query) {
      this.query = query;
    },
    setStoreQuery: debounce(function debouncedSetQuery(e) {
      const val = e.target.value.trim();
      this.$store.commit(this.filter.searchMutation, val);
    }, 400),
  },
};
</script>

<template>
  <div v-if="searchShown" class="m-search">
    <div
      class="mb-10 m-hero-search transition-slow md:mb-12 md:mx-5 xl:mx-8"
      :class="{ 'py-8 md:py-32 xl:py-64': !resultsShown }"
    >
      <div
        class="pt-10 pb-5 sm:pb-8 sm:pt-18 md:py-18 transition-slow">
        <div class="container transition-slow" :class="{ 'md:px-32 lg:px-48': !resultsShown }">
          <search-box />
        </div>
      </div>
    </div>
    <div class="container">
      <mobile-filters v-if="showMobileFilters" />
      <results v-if="resultsShown" />
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import { SET_RESULTS_SHOWN } from "@/search/store/mutations";

import SearchBox from "@/search/components/query/SearchBox.vue";
import Results from "@/search/components/results/Results.vue";
import MobileFilters from "@/search/components/filters/MobileFilters.vue";

import { scrollToTarget } from "@/search/helpers/scroll-to-helper";

export default {
  components: {
    SearchBox,
    Results,
    MobileFilters,
  },
  computed: {
    ...mapState({
      resultsShown: (state) => state.ui.resultsShown,
      searchShown: (state) => state.ui.searchShown,
    }),
    ...mapGetters(["showMobileFilters", "totalPages", "page"]),
  },
  watch: {
    totalPages() {
      this.scrollToContainer();
    },
    page() {
      this.scrollToContainer();
    },
  },
  methods: {
    hideResults() {
      if (this.resultsShown) {
        this.$store.commit(SET_RESULTS_SHOWN, false);
      }
    },
    scrollToContainer() {
      this.$nextTick(() => scrollToTarget(this.$el, {}));
    },
  },
};
</script>

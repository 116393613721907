<template>
  <checkbox-filter :filter="filter" :show-options="showOptions" @reset-filter="resetFilter">
    <template v-slot:before-checkboxes>
      <facet-search-box :filter="filter" />
    </template>
  </checkbox-filter>
</template>
<script>
import { mapState } from "vuex";

import CheckboxFilter from "@/search/components/filters/CheckboxFilter.vue";
import FacetSearchBox from "@/search/components/filters/FacetSearchBox.vue";

export default {
  components: {
    CheckboxFilter,
    FacetSearchBox,
  },
  props: {
    filter: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      hasQuery: false,
      refinements: [],
    };
  },
  computed: {
    ...mapState({
      search: (state) => state.search,
    }),
    showOptions() {
      return this.hasQuery || this.refinements.length > 0;
    },
  },
  created() {
    this.hasQuery = !!this.$store.state.search[this.filter.queryStateKey];
    this.refinements = this.$store.state.search.facets[this.filter.name];

    this.$watch(`search.${this.filter.queryStateKey}`, (query) => {
      this.hasQuery = !!query;
    });

    this.$watch(`search.facets.${this.filter.name}`, (refinements) => {
      this.refinements = refinements;
    });
  },
  methods: {
    resetFilter() {
      this.$store.commit(this.filter.searchMutation, "");
    },
  },
};
</script>

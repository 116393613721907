// Vendor
import 'virtual:svg-icons-register';

// Assets
import './css/index.css';

// Bootstrap
import setLoaded from '@/lib/setLoaded';
import initModules from '@/lib/initModules';
import modulesEnabled from '@/lib/modulesEnabled';

// Vue apps
import search from '@/search/app';

const { APP } = window;
APP.DEBUG = typeof APP.DEBUG !== 'undefined' ? APP.DEBUG : !process.env.NODE_ENV === 'production';

window.addEventListener('DOMContentLoaded', () => {
  setLoaded(APP);

  APP.modules = initModules(
    APP.modules, 
    modulesEnabled, 
    APP.DEBUG);

  if (APP.CONFIG && APP.CONFIG.SEARCH) {
    APP.search = search(APP.CONFIG.SEARCH);
  }

});

<template>
  <div class="flex flex-wrap m-teaser m-teaser--right">
    <div class="flex-1 lg:pr-16">
      <h3>
        <a :href="hit.url" class="no-underline" @click="trackGoResult(hit.uri)">
          {{ hit.title }}
        </a>
      </h3>
      <h6 v-if="hit.organisers">
        {{ hit.organisers.join(", ") }}
      </h6>
      <p>
        {{ hit.summary | truncate(250, "...") }}
        <a :href="hit.url" class="text-red">Read more</a>.
      </p>

      <div class="flex">
        <div class="flex-1 mr-2">
          <p v-if="hit.focus" class="mb-2">
            <span class="font-bold">Focus: </span>{{ hit.focus.join(", ") }}
          </p>
          <p v-if="hit.actions" class="mb-2">
            <span class="font-bold">Action: </span>{{ hit.actions.join(", ") }}
          </p>
        </div>
        <div class="flex-1 ml-2">
          <p v-if="hit.fundersExpanded" class="mb-2">
            <span class="font-bold">Funder(s): </span>
            <span v-for="(funder, i) in hit.fundersExpanded" :key="i">
              <a :href="funder.url">{{ funder.title }}</a>
              <template v-if="i < hit.fundersExpanded.length - 1"
                >,&nbsp;</template
              >
            </span>
          </p>
          <p v-if="hit.fundingAmount" class="mb-2">
            <span class="font-bold">Funding Amount: </span>{{ hit.fundingAmount }}
          </p>
          <p v-if="hit.status" class="mb-2">
            <span class="font-bold">Status: </span>{{ hit.status }}
          </p>
          <p v-if="hit.commencementYear" class="mb-2">
            <span class="font-bold">Commencement Year: </span>{{ hit.commencementYear }}
          </p>
        </div>
      </div>
      <p v-if="displayLocations" class="mb-2">
        <span class="font-bold">Locations: </span>
        <span v-for="(location, index) in displayLocations" :key="location.id">
          <a :href="location.url">{{ location.title }}</a
          ><template v-if="index < displayLocations.length - 1"
            >&nbsp;and&nbsp;</template
          >
        </span>
        <template v-if="displayLocations.length < locationsCount">
          and <a :href="hit.url">{{ locationsCount - displayLocations.length }} more</a>
        </template>
      </p>
    </div>
    <div v-if="isDesktop" class="hidden lg:block max-w-2xs">
      <hit-image v-if="hit.image" :hit="hit" class="w-full mb-5"></hit-image>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { sortBy } from "lodash";
import BaseHit from "@/search/components/results/BaseHit.vue";

export default {
  extends: BaseHit,
  data() {
    return {
      locationDisplayCount: 4,
    };
  },
  computed: {
    ...mapGetters(["isDesktop"]),
    locationsMaxDetail() {
      if (this.hit.locationsExpanded.length) {
        const maxLevel = Math.max(...this.hit.locationsExpanded.map((loc) => loc.level));
        return this.hit.locationsExpanded.filter((loc) => loc.level === maxLevel);
      }
      return [];
    },
    displayLocations() {
      return sortBy(this.locationsMaxDetail, "title").slice(0, this.locationDisplayCount);
    },
    locationsCount() {
      return this.locationsMaxDetail.length;
    },
  },
};
</script>

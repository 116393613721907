<template>
  <div>
    <component
      :is="filter.type"
      v-for="filter in filtersForUi"
      :key="filter.name"
      :filter="filter"
      :real-time="true"
      :values="filter.values"
    ></component>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

import CheckboxFilter from "@/search/components/filters/CheckboxFilter.vue";
import NumericRangeFilter from "@/search/components/filters/NumericRangeFilter.vue";
import SearcheableCheckboxFilter from "@/search/components/filters/SearcheableCheckboxFilter.vue";

export default {
  components: {
    CheckboxFilter,
    NumericRangeFilter,
    SearcheableCheckboxFilter,
  },
  computed: {
    ...mapGetters(["filtersForUi"]),
  },
};
</script>

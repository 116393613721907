import Vue from 'vue';
import Search from '@/search/Search.vue';
import VueLazyload from 'vue-lazyload';

import {
  SET_CONFIG,
  SET_ALGOLIA_CONFIG,
  SET_INDEX_NAME,
  SET_SEARCH_SHOWN,
  SET_FORCE_SEARCH_SHOWN,
  SET_QUERY,
  SET_RESULTS_SHOWN,
  STORE_QS,
} from '@/search/store/mutations';

import store from './store';
import SearchToggle from '@/search/components/SearchToggle.vue';

export default function initSearch(CONFIG) {
  const searchContainer = document.querySelector(CONFIG.SELECTORS.CONTAINER);

  if (!searchContainer) {
    return null;
  }

  Vue.use(VueLazyload, {
    preLoad: 1.3,
    error: CONFIG.PLACEHOLDER_IMAGE_URL,
    loading: CONFIG.PLACEHOLDER_IMAGE_URL,
    attempt: 1,
  });

  store.commit(SET_ALGOLIA_CONFIG, CONFIG.ALGOLIA);
  store.commit(SET_INDEX_NAME, CONFIG.INDEX_NAME);
  store.commit(SET_CONFIG, CONFIG);

  // set initial UI state

  if (CONFIG.START_OPEN) {
    store.commit(SET_SEARCH_SHOWN, true);
    store.commit(SET_RESULTS_SHOWN, true);
  }

  if (CONFIG.FORCE_OPEN) {
    store.commit(SET_FORCE_SEARCH_SHOWN, true);
  }

  if (CONFIG.INITIAL_QS) {
    store.commit(STORE_QS, CONFIG.INITIAL_QS);
  }

  if (CONFIG.QUERY) {
    store.algoliaPlugin.removeQsState();
    store.commit(SET_QUERY, CONFIG.QUERY);
  }

  if (CONFIG.PRELOAD_QUERY) {
    store.dispatch('preloadQuery', CONFIG.PRELOAD_QUERY);
  }

  if (CONFIG.SHOW_RESULTS_ON_INIT) {
    store.commit(SET_RESULTS_SHOWN, true);
    store.algoliaPlugin.search();
  }

  /* eslint-disable no-new */
  const app = new Vue({
    el: CONFIG.SELECTORS.CONTAINER,
    render: (h) => h(Search),
    store,
  });

  const toggles = [...document.querySelectorAll('[data-search-trigger]')].map((toggle) => {
    return new Vue({
      el: toggle,
      render: (h) =>
        h(SearchToggle, {
          props: {
            collapsedText: toggle.dataset.collapsedText,
            expandedText: toggle.dataset.expandedText,
            classes: [...toggle.classList],
            searchApp: app,
          },
        }),
      store,
    });
  });

  return {
    app,
    toggles,
    store,
    CONFIG,
  };
}

<template>
  <div :class="textColorClass" class="flex flex-wrap items-center" style="min-width: 115px;">
    <button type="button" class="px-3 py-4" aria-label="Previous Slide" @click.prevent="prev">
      <icon icon="arrow-back" :height="4" :width="4" class="w-4 h-4" />
    </button>
    <span>{{ currentIndex + 1 }} / {{ items.length }}</span>
    <button type="button" class="px-3 py-4" aria-label="Next Slide" @click.prevent="next">
      <icon icon="arrow" :height="4" :width="4" class="w-4 h-4" />
    </button>
  </div>
</template>
<script>
import Icon from "@/components/Icon.vue";

export default {
  components: {
    Icon,
  },
  props: {
    items: {
      required: true,
      type: Array,
    },
    currentIndex: {
      required: true,
      type: Number,
    },
    textColor: {
      required: false,
      type: String,
      default: "",
    },
  },
  computed: {
    textColorClass() {
      return `text-${this.textColor}`;
    },
  },
  methods: {
    prev() {
      this.$emit("prev");
    },
    next() {
      this.$emit("next");
    },
  },
};
</script>

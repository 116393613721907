<template>
  <span>
    <span v-if="totalHits" class="text-sm">
      Showing {{ firstHitOnPage }}-{{ lastHitOnPage }} of {{ totalHits }} results</span
    >
    <span v-else class="text-sm">0 results</span>
  </span>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["firstHitOnPage", "lastHitOnPage", "query", "totalHits"]),
  },
};
</script>

/* eslint-disable no-param-reassign, no-underscore-dangle */

import capitalize from "underscore.string/capitalize";

import {
  SET_ALGOLIA_READY,
  SET_BREAKPOINT,
  SET_CONFIG,
  SET_FORCE_SEARCH_SHOWN,
  SET_QUERY_FOCUS,
  SET_RESULTS_SHOWN,
  SET_SEARCH_SHOWN,
  TOGGLE_MOBILE_FILTERS,
  STORE_QS,
} from "@/search/store/mutations";

import BREAKPOINTS from "@/search/constants/breakpoints";

function generateBreakpoints(breakpoints) {
  return Object.keys(breakpoints).reduce((acc, name) => {
    acc[name] = false;
    return acc;
  }, {});
}

function generateBreakpointGetters(breakpoints) {
  return Object.keys(breakpoints).reduce((acc, name) => {
    acc[`is${capitalize(name, true)}`] = (state) => state.breakpoints[name];

    return acc;
  }, {});
}

export default {
  state: {
    algoliaReady: false,
    searchShown: true,
    forceSearchShown: false,
    resultsShown: false,
    queryHasFocus: true,
    config: null,
    resultsPerPageOptions: [5, 10, 25, 50],
    breakpoints: generateBreakpoints(BREAKPOINTS),
    mobileFiltersShown: false,
    storedQs: "",
  },
  mutations: {
    [SET_BREAKPOINT](state, { name, matches }) {
      state.breakpoints[name] = matches;
    },
    [SET_QUERY_FOCUS](state, hasFocus) {
      state.queryHasFocus = hasFocus;
    },
    [SET_RESULTS_SHOWN](state, shown) {
      state.resultsShown = shown;
    },
    [SET_SEARCH_SHOWN](state, shown) {
      if (!shown || state.forceSearchShown) {
        state.searchShown = shown;
      }
    },
    [SET_FORCE_SEARCH_SHOWN](state, shown) {
      state.forceSearchShown = shown;
    },
    [SET_ALGOLIA_READY](state) {
      state.algoliaReady = true;
    },
    [SET_CONFIG](state, config) {
      state.config = config;
    },
    [TOGGLE_MOBILE_FILTERS](state) {
      state.mobileFiltersShown = !state.mobileFiltersShown;
    },
    [STORE_QS](state, qs) {
      state.storedQs = qs;
    },
  },
  getters: {
    ...generateBreakpointGetters(BREAKPOINTS),
    isMobile(state, getters) {
      return !getters.isDesktop;
    },
    isDesktop(state, getters) {
      return getters.isMd;
    },
    showMobileFilters(state, getters) {
      return getters.isMobile && state.mobileFiltersShown;
    },
    placeholderUrl: ({ config }) => (config ? config.PLACEHOLDER_IMAGE_URL : null),
    showSearchOnInit: ({ config }) => (config ? config.SHOW_SEARCH_ON_INIT : null),
  },
};

<template>
  <div class="mb-8">
    <div class="relative">
      <div role="group" aria-roledescription="slide" :arial-label="ariaLabel">
        <figure class="block w-full" aria-labelledby="">
          <img
            class="relative block w-full p-0 m-0"
            :src="currentImage.large"
            :alt="currentImage.title"
          />
        </figure>
      </div>

      <div v-if="items.length > 1" class="absolute bottom-0 right-0 z-10 bg-white opacity-75">
        <slideshow-controls
          :items="items"
          :current-index="currentIndex"
          text-color="accent-blue"
          @prev="goPrev"
          @next="goNext"
        />
      </div>
    </div>
    <div class="flex flex-wrap my-1 -mx-1">
      <button
        v-for="image in items"
        :key="image.large"
        :title="'Show image: ' + image.title"
        :disabled="isCurrent(image)"
        class="w-1/3 p-1 lg:w-1/4"
        type="button"
        @click="setCurrent(image)"
      >
        <img
          :src="image.small"
          :class="{ 'opacity-50': !isCurrent(image) }"
          :alt="image.title"
          class="block w-full"
        />
      </button>
    </div>
  </div>
</template>
<script>
import SlideshowControls from "./SlideshowControls.vue";

export default {
  components: {
    SlideshowControls,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    id: {
      type: String,
      default: () => `Slideshow-${Math.floor(Math.random() * 9999999999999)}`,
    },
  },
  data() {
    return {
      currentIndex: 0,
    };
  },
  computed: {
    imageCount() {
      return this.items.length;
    },
    currentImage() {
      return this.items[this.currentIndex];
    },
    ariaLabel() {
      return `${this.currentIndex + 1} of ${this.items.length}`;
    },
  },
  methods: {
    goNext() {
      this.currentIndex = this.currentIndex === this.imageCount - 1 ? 0 : this.currentIndex + 1;
    },
    goPrev() {
      this.currentIndex = this.currentIndex === 0 ? this.imageCount - 1 : this.currentIndex - 1;
    },
    setCurrent(image) {
      this.currentIndex = this.items.findIndex((i) => i === image);
    },
    isCurrent(image) {
      return image === this.currentImage;
    },
  },
};
</script>

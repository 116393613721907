<template>
  <div class="fixed top-0 bottom-0 left-0 right-0 z-10 w-full pb-32 overflow-y-scroll bg-white">
    <div class="container">
      <div class="flex">
        <button
          class="py-2 my-2 ml-auto font-bold text-right link--close text-red"
          @click="toggleMobileFilters"
        >
          Close
        </button>
      </div>
      <div class="flex mb-5">
        <h4 class="h--trim">Filters</h4>
        <div class="ml-auto">
          <reset-all-filters></reset-all-filters>
        </div>
      </div>
      <!-- <query-input /> -->
      <all-filters />
      <mobile-filter-button />
    </div>

    <div class="fixed bottom-0 left-0 z-20 w-full p-4 bg-white border-t border-grey">
      <button type="button" class="block w-full btn" @click="toggleMobileFilters">
        <div class="flex items-center justify-center">
          <icon icon="arrow" class="btn__icon" aria-hidden="true" />
          <div class="w-auto pt-1">
            <span class="mt-2 leading-tight">
              Show Projects
            </span>
          </div>
        </div>
      </button>
    </div>
  </div>
</template>
<script>
import { TOGGLE_MOBILE_FILTERS } from "@/search/store/mutations";
import { mapGetters } from "vuex";

import modalHelper from "@/mixins/modalHelper";

import AllFilters from "@/search/components/filters/All.vue";
import MobileFilterButton from "@/search/components/filters/MobileFilterButton.vue";
// import QueryInput from "@/search/components/query/QueryInput";
import ResetAllFilters from "@/search/components/filters/ResetAllFilters.vue";
import Icon from "@/components/Icon.vue";

export default {
  components: {
    AllFilters,
    MobileFilterButton,
    Icon,
    // QueryInput,
    ResetAllFilters,
  },
  mixins: [modalHelper],
  computed: {
    ...mapGetters(["totalHits", "showMobileFilters"]),
  },
  methods: {
    toggleMobileFilters() {
      this.$store.commit(TOGGLE_MOBILE_FILTERS);
    },
  },
};
</script>

<template>
  <a :href="hit.uri" class="block" @click="trackGoResult(hit.uri)">
    <img v-lazy="imageUrl" :alt="hit.title" class="w-full" />
  </a>
</template>
<script>
import trackingHelper from "@/search/mixins/trackingHelper";

import { mapGetters } from "vuex";

export default {
  mixins: [trackingHelper],
  props: {
    hit: {
      required: true,
      type: Object,
    },
  },
  computed: {
    ...mapGetters(["placeholderUrl"]),
    imageUrl() {
      return this.hit.image && this.hit.image.url ? this.hit.image.url : this.placeholderUrl;
    },
  },
};
</script>

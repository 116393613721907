<template>
  <div>
    <label for="search" class="hidden">Search</label>
    <input
      id="search"
      class="bg-white font-serif text-lg md:text-xl font-medium text-black w-full pt-5 pb-4 pr-5 outline-none"
      type="search"
      name="search"
      aria-label="Search"
      :placeholder="placeholder"
      :value="query"
      autocomplete="off"
      autocorrect="off"
      autocapitalize="off"
      spellcheck="true"
      @input="setQuery"
      @focus="onFocus"
      @blur="onBlur"
    />
  </div>
</template>

<script>
import { debounce } from "lodash";
import { mapState } from "vuex";

import { SET_QUERY, SET_QUERY_FOCUS } from "@/search/store/mutations";

export default {
  props: {
    placeholder: {
      type: String,
      default: "Find a project...",
    },
  },
  computed: {
    ...mapState({
      query: ({ search }) => search.query,
    }),
  },
  created() {
    this.$nextTick(() => this.$el.focus());
  },
  methods: {
    setQuery: debounce(function debouncedSetQuery(e) {
      this.$store.commit(SET_QUERY, e.target.value.trim());

      this.$emit("change");
    }, 400),
    resetQuery() {
      this.$store.commit(SET_QUERY, "");
    },
    onFocus() {
      this.$store.commit(SET_QUERY_FOCUS, true);
    },
    onBlur() {
      this.$store.commit(SET_QUERY_FOCUS, false);
    },
  },
};
</script>

<template>
  <div v-if="hasMeta">
    <div v-if="meta.phoneNumber">
      <a :href="'tel:' + meta.phoneNumber" @click="trackGoMeta('phone')">{{ meta.phoneNumber }}</a>
    </div>
    <div v-if="meta.emailAddress">
      <a
        :href="'mailto:' + meta.emailAddress"
        style="word-break: break-all;"
        @click="trackGoMeta('email')"
        >{{ meta.emailAddress }}</a
      >
    </div>
    <span v-if="meta.date || meta.venue" class="m-search__crumb-link">
      {{ meta.date.localeDate
      }}<template v-if="meta.venue"
        >, {{ meta.venue }}</template
      >
    </span>
    <span v-else-if="breadcrumb.length" class="m-search__crumb-link">
      / {{ breadcrumb.join(" / ") }}
    </span>
  </div>
</template>
<script>
import trackingHelper from "@/search/mixins/trackingHelper";

export default {
  mixins: [trackingHelper],
  props: {
    meta: {
      required: true,
      type: Object,
    },
    breadcrumb: {
      required: false,
      type: Array,
      default() {
        return [];
      },
    },
  },
  computed: {
    hasMeta() {
      return this.meta.date || this.meta.venue || this.meta.phoneNumber || this.meta.emailAddress;
    },
  },
};
</script>

import { fundersSelect } from '@/components/molecules/funders-select/index';
import { locationSelect } from '@/components/molecules/location-select/index';
import { monthYearField } from '@/components/molecules/month-year-field/index';
import { slideshow } from '@/components/molecules/slideshow/index';

export default {
  fundersSelect,
  locationSelect,
  monthYearField,
  slideshow,
};

<template>
  <div :id="id" class="control mb-12 flex">
    <div class="w-1/2 flex">
      <label :for="monthId" class="mr-4 items-center flex-initial self-center">Month</label>
      <div class="select__wrapper flex-1">
        <select :id="monthId" v-model="monthSelected" class="select">
          <option v-for="month in months" :key="month" :value="month">{{ month }}</option>
        </select>
      </div>
    </div>
    <div class="w-1/2 flex">
      <label :for="yearId" class="ml-4 mr-4 items-center flex-initial self-center">Year</label>
      <div class="select__wrapper flex-1">
        <select :id="yearId" v-model="yearSelected" class="select">
          <option v-for="year in years" :key="year" :value="year">{{ year }}</option>
        </select>
      </div>
    </div>

    <input :name="name" :value="formattedDate" type="hidden" />
  </div>
</template>

<script>
import { range } from "lodash";

export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Object,
      default: () => ({
        month: null,
        year: null,
      }),
    },
  },
  data() {
    return {
      monthSelected: this.value.month,
      yearSelected: this.value.year,
      id: `month-year-${Math.floor(Math.random() * 9999999)}`,
    };
  },
  computed: {
    currentYear() {
      return new Date().getFullYear();
    },
    minYear() {
      return this.currentYear - 5;
    },
    formattedDate() {
      if (!this.monthSelected || !this.yearSelected) {
        return null;
      }

      return `${this.yearSelected}-${this.monthSelectedForSubmit.padStart(2, "0")}-01`;
    },
    monthId() {
      return `${this.id}-month`;
    },
    months() {
      return [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
    },
    monthSelectedForSubmit() {
      return `${this.months.findIndex((m) => m === this.monthSelected)}`;
    },
    yearId() {
      return `${this.id}-year`;
    },
    years() {
      return range(this.minYear, this.currentYear + 1);
    },
  },
};
</script>
